import React, { useEffect } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import logo from "../images/logo.svg"
import styled from "styled-components"
import { FaTelegramPlane } from "react-icons/fa"

import Aos from "aos"

const IndexPage = () => {
  useEffect(() => {
    Aos.init({ duration: 1000, once: true })
  }, [])
  return (
    <Layout>
      <Seo title="Finanzas de Bolsillo" />
      <Body className="css-selector">
        <div className="grid grid-cols-3 w-full bg-gradient-to-t from-black items-center">
          <Header className="col-span-3 justify-self-center absolute text-center w-full">
            <h1
              className="text-3xl md:text-6xl pb-5 text-gray-100 font-montserat font-bold filter drop-shadow-xl"
              data-aos="fade-down"
            >
              Finanzas de Bolsillo
            </h1>

            <h2
              className="text-white text-base md:text-md lg:text-xl font-montserat"
              data-aos="fade-down"
            >
              <b>Налоги. Банки. Закон.</b>
            </h2>
          </Header>

          <div
            className="col-span-3 justify-self-center flex items-center relative"
            style={{ height: "90vh" }}
          >
            <div className="blob absolute" />

            <a
              className="text-sm md:text-xl lg:text-4xl text-white font-montserat hover:text-blue-100 hover:underline"
              href="https://t.me/FinanzasDeBolsillo"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Telegram"
            >
              <Logo
                src={logo}
                alt="Finanzas de bolsillo. Barcelona"
                data-aos="flip-left"
              />
            </a>
          </div>

          {/* footer */}
          <Footer
            className="col-span-3 justify-self-center absolute text-center"
            data-aos="fade-down"
          >
            <h3 className="text-white text-base md:text-md lg:text-xl pb-5 font-montserat">
              приглашаем посетить
              <br /> наш Telegram-канал
            </h3>
            <div className="w-full">
              <span>
                <FaTelegramPlane className="text-white text-4xl mr-5 inline" />
              </span>
              <span>
                <a
                  className="text-lg md:text-xl lg:text-4xl text-white font-montserat hover:text-blue-100 hover:underline transition delay-150 duration-300 ease-in-out"
                  href="https://t.me/FinanzasDeBolsillo"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Telegram"
                >
                  https://t.me/FinanzasDeBolsillo
                </a>
              </span>
            </div>
          </Footer>
        </div>
      </Body>
    </Layout>
  )
}

export default IndexPage

const Logo = styled.img`
  width: 20vh;
`
const Footer = styled.div`
  bottom: 5vh;
  width: 100%;
`
const Header = styled.div`
  top: 10vh;
`
const Body = styled.div`
  height: 100%;
  width: 100%;
`
